@import '../config';
@import '../core/breakpoints';

.buttonRow {
	display: flex;
	gap: 10px;
	justify-content: center;
}

.button {
	display: inline-flex;
	text-align: center;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
	line-height: 1.25;
	border-radius: $button-radius;
	border: 0;
	cursor: pointer;
	padding: 15px 40px;
	@extend .trans;
	@include max940px {
		display: block;
	}

	@include max640px {
		padding: 15px 30px;
		font-size: 15px;
	}

	@include max480px {
		padding: 10px 20px;
		font-size: 13px;
	}
}

.button--blue {
	color: $white;
	background-color: #0F66DC;
	&:hover {
		background-color: $blue;	
	}
}

.button--white {
	background-color: $white;
	color: $blue;
}

.button--link {
	display: flex;
	background-color: transparent;
	justify-content: flex-start;
	align-items: center;
	font-weight: bold;
	color: $dark-gray;
	text-decoration: none;
	padding: 0;
	&:after {
		content: "";
		display: flex;
		width: 27px;
		height: 15px;
		margin-left: 15px;
		background-image: url(../../images/ui/arrow-blue.svg);
		background-size: contain;
		background-repeat: no-repeat;
		@extend .trans;
	}

	&:hover {
		&:after {
			margin-left: 20px;
		}
	}
}