@import '../config';

.news {
	.news__row {
		justify-content: center;
		gap: 2rem;
	}

	.news__item {
		max-width: 350px;
		width: 100%;
	}

	.news__image {
		display: block;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		width: 100%;
	}

	.news__content {
		display: block;
		background-color: #EFF7FF;
		padding: 2rem;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		
		.news__date {
			display: flex;
			color: #929292;
			margin-bottom: 1rem;
			align-items: center;
			&:before {
				content: "";
				
				background-image: url(#{$images-path}/ui/icons/icon-date.svg);
				background-repeat: no-repeat;
				width: 20px;
				height: 20px;
				margin-right: 1rem;
			}
		}

		h1 {
			margin-bottom: 1.5rem;
			font-size: 1.5rem;
			color: #232C33;
			text-align: left;
			min-height: 56px;
		}

		p {
			min-height: 130px;
		}
	}
}