@import '../config';

.cover {
	position: relative;
	margin-bottom: 0;
	.cover__slide {
		position: relative;
	}

	.cover__image {
		display: block;
		max-width: 1920px;
		width: 100%;
		margin: 0 auto;
		img {
			display: block;
			width: 100%;
		}
	}

	.cover__wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: absolute;
		top: 50%; left: 50%;
		transform: translate(-50%, -50%);
	}

	h1 {
		font-size: $hero-font-size;
		color: $white;
		margin-bottom: 25px;
		text-align: center;
		font-weight: 300;
		@media screen and (max-width: 1270px) {
			font-size: 45px;
		}

		@include max940px {
			margin-bottom: 30px;
		}

		@include max640px {
			font-size: 37.5px;
			margin-bottom: 30px;
		}

		@include max480px {
			font-size: 26px;
			margin-bottom: 20px;
		}

		.bold {
			font-weight: 700;
		}
	}

	.cover__subheadline {
		color: $white;
		font-size: 24px;
	}

	.cover__copy {
		margin-bottom: 2rem;
	}
}

.cover--video {
	position: relative;
	height: 650px;
	overflow: hidden;
	
	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100px;
		top: 50%;
		background-image: url("#{$images-path}/ui/oblouk.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;	
		z-index: 100;
	}
	.cover__image--video {
		video {
			position: absolute;
			top: 0; left: 0;
		}
	}
}