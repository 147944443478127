@import '../config';
@import '../core/breakpoints';

.navigation {
	display: flex;
	align-items: center;
	.navigation__list {
		display: flex;
		justify-content: space-between;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.navigation__item {
		margin: 0 1.5rem;
		padding: 30px 0 25px;
		color: $white;
		font-weight: 700;
		&:hover {
			.navigation__link {
				color: $white;
			}
		}
	}

	.navigation__item--active {
		.navigation__link {
			font-weight: bold;
			text-decoration: none;
		}
	}

	.navigation__item--has-submenu {
		background-image: url(#{$images-path}/ui/small-arrow-down.svg);	
		background-position: 50% 77.5%;
		background-repeat: no-repeat;
		&.show {
			.submenu {
				display: flex;
				z-index: 100;
			}	
		}
		@extend .trans;
		&:hover {
			.submenu {
				display: flex;
				z-index: 100;
			}
		}
	}

	.navigation__link {
		font-size: 13px;
		font-weight: 600;
		text-decoration: none;
		@extend .trans;
		img + span {
			display: none;
		}
	}
}
