.blueBox {
    padding: 50px;
    box-sizing: border-box;
    max-width: 440px;
    width: 100%;
    margin: 0 auto;
    background-color: $blue;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
    @include max640px {
        padding: 30px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        font-size: 20px;
        margin: 0 0 15px;
        color: $white;
        text-align: left;
    }

    ul {
        margin-bottom: 0;
        li {
            text-align: left;
            color: $white;
        }
    } 
    
    p {
        font-size: 20px;
        color: $white;
        @include max640px {
            font-size: 18px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.blueBox--light {
    background-color: $light-blue;
    p {
        color: $black;
    }
}

.blueBox--news {
    background-color: $light-blue;
    margin-left: 0;
    margin-top: 3rem;
    h3 {
        color: $black;
        font-size: 20px;
    } 

    p {
        color: #6d6d6d;
        font-size: 1rem;
        text-align: left;
    }
}