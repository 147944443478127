@import '../config';

.header {
	position: absolute;
	top: 0;
	left: 0; right: 0;
	z-index: 100;
	background-color: #24242445;
	backdrop-filter: blur(3px);
	@media screen and (max-width: $hamburger-breakpoint) {
		padding: 40px 20px 20px;
	}

	@include max480px {
		padding: 20px;
	}

	.header__wrapper {
		@include max480px {
			padding: 0;
		}
	}

	.header__row {
		display: flex;
	}

	.header__row--logo {
		justify-content: space-between;
		margin-bottom: 30px;
		@include max480px {
			margin-bottom: 20px;	
		}
	}

	.header__row--nav {
		justify-content: space-between;
	}

	.button {
		text-transform: uppercase;
	}
}

.headerLogo {
	display: flex;
	align-items: center;
	
	.headerLogo__image {
		max-width: 130px;
		width: 100%;
		@include max480px {
			max-width: 130px;
		}
	}
}

.headerSearch {
	.headerSearch__input {
		margin-right: 30px;
	}
}

.headerNavigation {
	.navigation {
		@media screen and (max-width: $hamburger-breakpoint) {
			display: none;
		}
	}
}
