@import '../core/helpers';
@import '../config';

.cookies {
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	max-width: 450px;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.85);
	z-index: 999;
	border-radius: $base-radius * 2;
	box-shadow: 0 25px 50px 0 rgba(55,58,64,0.10);
	@include max940px {
		width: 100%;
		left: 0; right: 0; bottom: 0;
		border-radius: 0;
		box-shadow: none;
		max-width: 100%;
	}

	&:hover {
		background-color: rgba(255, 255, 255, 1);
	}

	.cookies__row {
		flex-direction: column;
		align-items: center;
		padding: 1.5rem;
	}

	.cookies__col--message {
		margin-bottom: 1rem;
	}

	.cookies__message {
		margin: 0;
		color: black;
		font-weight: 400;
		text-align: center;
		font-size: 0.8rem;
	}

	.button {
		padding: 0.5rem 1rem;
		font-size: 0.8rem;
	}
}