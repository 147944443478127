@import '../config';

.articles {
	padding-top: 120px;
	@include max640px {
		padding-top: 60px;
	}

	.articles__row {
		flex-flow: unset;
		margin-bottom: 20px;
		@include max940px {
			flex-direction: column;
		}
	}

	.articles__row--signature {
		.articles__col {
			padding: 0;
		}
	}

	.articles__row--inline {
		align-items: flex-start;
		gap: 50px;
	}

	.articles__row--center {
		justify-content: center;
		text-align: center;
	}

	h1 {
		font-size: 1.5rem;
		line-height: 1.5;
		margin-bottom: 1.25rem;
		text-align: left;
	}

	p {
		color: #353535;
		margin-bottom: 1.25rem;
	}

	.articles__col {
		width: 50%;
		@include max940px {
			width: 100%;
		}
	}

	.articles__block {
		margin-bottom: 90px;
		@include max640px {
			margin-bottom: 30px;
		}

		.articles__col--copy {
			padding: 45px 70px;
			@include max940px {
				padding: 30px;	
			}

			@include max640px {
				padding: 15px;
			}
		}
	}

	.articles__row--signature {
		.articles__col--copy {
			padding: 0 90px; 
		}
	}

	.articles__block--blue {
		padding: 0;
		margin-bottom: 0;
		.articles__row {
			align-items: center;
			margin: 15px 0 30px;
			@include max940px {
				flex-direction: column;
			}
		}

		.articles__col {
			@include max940px {
				max-width: 650px;
				width: 100%;
			}
		}
		
		.articles__col--copy {
			background-color: $light-blue;
			@include max1024px {
				padding: 30px;
				order: -1;
				border-radius: 10px 10px 0 0;
			}
		}

		.articles__image {
			@include max1024px {
				border-radius: 0 0 10px 10px;
			}	
		}

		h2 {
			color: $blue;
			font-size: 30px;
			@include max640px {
				font-size: 24px;	
			}
		}

		h3 {
			margin-top: 0;
			@include max640px {
				font-size: 18px;	
			}
		}
	}

	.articles__block--box {
		.articles__row {
			margin-top: 60px;
			background-color: $light-blue;
		}
	}

	.articles__block--center {
		text-align: center;
	}

	.articles__col--copy {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		box-sizing: border-box;
	}

	.articles__icon {
		margin-bottom: 1.25rem;
		max-width: 50px;
		width: 100%;
		img {
			display: block;
			width: 100%;
		}
	}

	.articles__image {
		display: block;
		width: 100%;
	}

	.article__table {
		table {
			font-size: 0.9rem;

			th {
				text-align: left;
			}

			th, td {
				padding: 0.75rem 0.5rem;
				border-bottom: 1px solid #dcdcdc;
			}

			tr:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
	}

	.article__table--overlaping {
		overflow-x: auto;
		table {
			width: 600px;
		}
		
	}
}

.articles--hp {
	.articles__wrapper {
		max-width: 1150px;
	}

	.articles__row {
		border-radius: 10px;
		box-shadow: 0px 0px 8px 0px #00000026;
		@media screen and (max-width: 890px) {
			flex-direction: column;
		}
	}

	.articles__block {
		.articles__col {
			@media screen and (max-width: 890px) {
				max-width: 580px;
				width: 100%;
				margin: 0 auto;
				
			}
		}
		
		.articles__col--image {
			border-radius: 0 10px 10px 0;
			@media screen and (max-width: 890px) {
				order: -1;
			}
		}

		.articles__col--copy {
			@media screen and (max-width: 890px) {
				padding: 45px 60px;
			}	

			@media screen and (max-width: 480px) {
				padding: 30px;
			}
		}
	}
}

.articles--content {
	position: relative;
	margin-top: -30px;
	padding-top: 0;
	@include max1024px {
		margin-top: 60px;	
	}

	.articles__row {
		margin: 50px 0;
		@include max640px {
			margin: 30px 0;	
		}
	}
    
	h1 {
		font-size: 35px;
	}

	h2 {
		font-size: 24px;
	
	}

	.perex {
		font-weight: bold;
	}
}

.articles--partners {
	margin-top: 30px;
	.articles__image {
		max-width: 350px;
		margin: 0 auto;
	}
}

.articles--news {
	.articles__col {
		width: 100%;
	}

	.articles__col--copy {
		padding: 45px 90px;
		max-width: 660px;
	}
}

.articles--legal {
	//margin-top: 100px;
	h1 {
		font-size: 2.25rem;
	}

	h2 {
		font-size: 1.5rem;
		margin: 2rem 0 1rem;
	}

	.signature {
		margin: 2rem 0;
	}
}