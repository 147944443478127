@import '../config';

.tiles {
	position: relative;
	z-index: 100;
	margin-bottom: 0;
	@media screen and (max-width: 1120px) {
		margin-top: 60px;
	}

	.tiles__wrapper {
		padding-bottom: 120px;
		border-bottom: 1px solid #c7c6c6;
	}

	.tiles__row {
		gap: 60px;
		justify-content: center;
		@include max640px {
			gap: 30px;	
		}
	}

	.tile {
		max-width: 280px;
	}
}

.tiles--category {
	margin-top: 4rem;
	.tiles__row {
		gap: 30px;
	}

	.tile {
		max-width: 390px;
		.tile__copy {
			min-height: 70px;
		}
	}
}

.tiles--hp {
	margin-top: 60px;

	h2 {
		font-size: 50px;
		margin-bottom: 2rem;
	}

	.tiles__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0;
	}

	.tiles__col {
		width: 50%;
	}

	.tiles__col--copy {
		padding-left: 40px;
		box-sizing: border-box;
		p {
			max-width: 450px;
			width: 100%;
			font-size: 20px;
			font-weight: 300;
			margin-bottom: 2rem;
		}
	}
	
	.tiles__col--tiles {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		gap: 30px;
	}

	.tile {
		max-width: 280px;
	}
}

.tiles--partners {
	.tile {
		.tile__icon {
			max-width: 230px;
		}	
	}
}

.tile {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 40px;
	border-radius: 10px;
	box-sizing: border-box;
	text-align: left;
	background-color: $light-blue;
	text-decoration: none;
	
	&:hover {
		.button--link {
			&:after {
				margin-left: 20px;
			}
		}
	}

	.tile__icon {
		display: block;
		max-width: 45px;
		margin-bottom: 20px;
		&.tile__smallicon {
			padding: 20px 0;
		}
	}

	.tile__headline {
		display: block;
		font-size: 1.5rem;
		margin-bottom: 0.5rem;
		font-weight: bold;
		color: $dark-gray;
		& + .tile__link {
			margin-top: 10px;
		}
	}

	.tile__copy {
		min-height: 102px;
		line-height: 1.7;
		color: $light-gray;
	}
}
