.contact {

    .contact__block {
        display: block;
        margin-bottom: 30px;
    }

    .contact__address {
        font-style: normal;
        strong {
            font-size: 17px;
        }
    }

    .contact__map {
        iframe {
            width: 100%;
            height: 450px;
        }
    }
}